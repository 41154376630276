<template>
  <div class="student-manage-edit">
    <div class="student-manage-edit-title">
      <div class="text">
        <span>学生详情</span>
        <div class="line"></div>
      </div>
    </div>
    <div class="first-panel" style="margin-top: 2.65625vw">
      <div class="panel-header">
        <div class="title">基础信息</div>
        <div class="button">
          <img
            :src="firstPanelTurnIcon"
            alt=""
            @click="handleItem('base')"
            :class="{ 'turn-icon-class': !showBaseFlag }"
          />
        </div>
      </div>
      <div class="panel-content" v-show="showBaseFlag">
        <el-form
          class="content-form"
          :model="studentBaseForm"
          ref="studentBaseForm"
          label-width="6.25vw"
        >
          <el-row>
            <el-col :span="8">
              <el-row>
                <el-col :span="6">
                  <el-form-item>
                    <el-image
                      style="width: 5.20833vw; height: 5.20833vw"
                      :src="studentBaseForm.coverImg"
                      :preview-src-list="srcList"
                    >
                    </el-image>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item>
                    <el-image
                      style="width: 5.20833vw; height: 5.20833vw"
                      :src="studentBaseForm.photoImg"
                      :preview-src-list="srcList"
                    >
                    </el-image>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-col>
            <el-col :span="8">
              <el-form-item
                class="form-choose-item"
                label="学生姓名："
              >
                <div class="form-text"><span>{{ studentBaseForm.studentName }}</span><div class="icon">{{ studentBaseForm.sex }}</div></div>
              </el-form-item>
              <el-form-item class="form-choose-item" label="昵称：">
                <div class="form-text">{{ studentBaseForm.nickName }}</div>
              </el-form-item>
              <el-form-item class="form-choose-item" label="家庭住址：">
                <div class="form-text">{{ studentBaseForm.address }}</div>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item class="form-choose-item" label="学生编号：">
                <div class="form-text">{{ studentBaseForm.studentNum }}</div>
              </el-form-item>
              <el-form-item class="form-choose-item" label="学籍号：">
                <div class="form-text">{{ studentBaseForm.studentStudyNum }}</div>
              </el-form-item>
              <el-form-item class="form-choose-item" label="身份证号：">
                <div class="form-text">{{ studentBaseForm.personID }}</div>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
    <div class="first-panel">
      <div class="panel-header">
        <div class="title">入园信息</div>
        <div class="button">
          <img
            :src="firstPanelTurnIcon"
            alt=""
            @click="handleItem('enter')"
            :class="{ 'turn-icon-class': !showEnterFlag }"
          />
        </div>
      </div>
      <div class="panel-content" v-show="showEnterFlag">
        <el-form
          class="content-form"
          :model="studentEnterForm"
          ref="studentEnterForm"
          label-width="6.25vw"
        >
          <el-row>
            <el-col :span="8">
              <el-form-item
                label="学校："
                class="form-choose-item"
              >
                <div class="form-text">{{ studentEnterForm.schoolName }}</div>
              </el-form-item>
              <el-form-item label="入园日期：" class="form-choose-item">
                <div class="form-text">{{ studentEnterForm.enterDate }}</div>
              </el-form-item>
              <el-form-item label="离校日期：" class="form-choose-item">
                <div class="form-text">{{ studentEnterForm.leaveDate }}</div>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                label="年级："
                class="form-choose-item"
              >
                <div class="form-text">{{ studentEnterForm.gradeName }}</div>
              </el-form-item>
              <el-form-item class="form-choose-item" label="英文名：">
                <div class="form-text">{{ studentEnterForm.englishName }}</div>
              </el-form-item>
              <el-form-item
                label="在校状态："
                class="form-choose-item"
              >
                <div class="form-text">{{ studentEnterForm.enterStatus }}</div>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                label="班级："
                class="form-choose-item"
              >
                <div class="form-text">{{ studentEnterForm.className }}</div>
              </el-form-item>
              <el-form-item class="form-choose-item" label="学届：">
                <div class="form-text">{{ studentEnterForm.year }}</div>
              </el-form-item>
              <el-form-item label="学生状态：" class="form-choose-item">
                <div class="form-text">{{ studentEnterForm.status }}</div>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
    <div class="first-panel">
      <div class="panel-header">
        <div class="title">家长信息</div>
        <div class="button">
          <img
            :src="firstPanelTurnIcon"
            alt=""
            @click="handleItem('parent')"
            :class="{ 'turn-icon-class': !showParentFlag }"
          />
        </div>
      </div>
      <div class="panel-content" v-show="showParentFlag">
        <div class="table-content">
          <el-table
            ref="parentsTable"
            :data="tableData"
            v-loading="tableLoading"
            style="width: 100%"
          >
            <el-table-column
              prop="parentName"
              label="家长姓名"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="parentPhone"
              label="手机号码"
              width="200"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="relationship"
              label="关系"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="firstPerson"
              label="第一联系人"
              align="center"
            >
              <template slot-scope="scope">
                <el-switch v-model="scope.row.firstPerson"></el-switch>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="180">
              <template slot-scope="scope">
                <span class="table-del" @click="handleDel(scope.row)"
                  >取消关联
                </span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <div class="first-panel">
      <div class="panel-header">
        <div class="title">分班记录</div>
        <div class="button">
          <img
            :src="firstPanelTurnIcon"
            alt=""
            @click="handleItem('distributeClass')"
            :class="{ 'turn-icon-class': !showDistributeClassFlag }"
          />
        </div>
      </div>
      <div class="panel-content" v-show="showDistributeClassFlag">
        <div class="table-content">
          <el-table
            ref="distributeClassTable"
            :data="distributeClassTableData"
            v-loading="distributeClassTableLoading"
            style="width: 100%"
          >
            <el-table-column
              prop="handlePerson"
              label="操作人"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="handlePersonPhone"
              label="操作手机"
              width="200"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="distributeClassRecord"
              label="分班记录"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="distributeClassTime"
              label="分班时间"
              align="center"
            ></el-table-column>
          </el-table>
          <div v-if="pagination && ipagination.total > 0" class="l-pages">
            <div class="total-num">
              显示第{{ showNum }}条记录，共{{ ipagination.total }}条记录
            </div>
            <el-pagination
              @current-change="handleCurrentChange"
              :current-page.sync="this.ipagination.num"
              @size-change="handleSizeChange"
              :page-size="ipagination.size"
              :page-sizes="ipagination.pageSizes"
              layout="prev, pager, next, sizes"
              :total="ipagination.total"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import firstPanelTurnIcon from "@/assets/img/first-panel-turn-icon.png";
export default {
  name: "studentDetail",
  data() {
    return {
      //基本信息显隐标识
      showBaseFlag: true,
      //入园信息显隐标识
      showEnterFlag: true,
      //家长信息显隐标识
      showParentFlag: true,
      //分班记录显隐标识
      showDistributeClassFlag: true,

      firstPanelTurnIcon, //旋转按钮

      studentBaseForm: {
        coverImg: "",
        photoImg: "",
        studentName: "",
        nickName: "",
        address: "",
        sex: "1",
        studentNum: "",
        studentStudyNum: "",
        personID: "",
      }, //基本信息

      studentEnterForm: {
        schoolName: "",
        enterDate: "",
        leaveDate: "",
        gradeName: "",
        englishName: "",
        enterStatus: "",
        className: "",
        year: "",
        status: "",
      }, //入园信息

      tableData: [], //家长信息列表数据
      tableLoading: false, //家长信息列表loading

      distributeClassTableData: [], //分班记录列表数据
      distributeClassTableLoading: false, //分班记录列表loading
      pagination: false, //分页显隐标识
      ipagination: {
        num: 1,
        size: 10,
        pageSizes: [10, 20, 50, 100, 500],
        total: 0,
      }, //分页对象

      gradeOptions: [], //年级下拉
      classOptions: [], //班级下拉
      enterStatusOptions: [], //在校状态下拉
      statusOptions: [], //学生状态下拉
      schoolOptions: [], //学校下拉
      relationshipOptions: [], //关系下拉
      cardTypeOptions: [], //证件类型下拉
    };
  },
  computed: {
    showNum() {
      let start = (this.ipagination.num - 1) * this.ipagination.size + 1;
      let end =
        this.ipagination.num * this.ipagination.size > this.ipagination.total
          ? this.ipagination.total
          : this.ipagination.num * this.ipagination.size;
      return this.ipagination.total === 0
        ? this.ipagination.total
        : `${start}-${end}`;
    },
  },
  methods: {
    /**
     * 点击切换页码
     */
    handleCurrentChange(val) {
      this.ipagination.num = val;
    },
    handleSizeChange(size) {
      this.ipagination.size = size;
      this.ipagination.num = 1;
    },
  },
};
</script>
<style lang="scss" scoped>

.form-text{
  width: 100%;
}
</style>